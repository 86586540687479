import styles from "./Gifts.module.scss"
import {useContext} from "react"
import {ModalContext} from "../../../../../contexts/ModalContext"

export default function Gifts() {
	const {handleModal} = useContext(ModalContext)

	return (
		<div className={styles.gifts}>
			<div
				onClick={() =>
					handleModal("Здравствуйте! Напишите свой номер, и я подробно расскажу обо всех доступных преимуществах от OMODA и нашего салона.")
				}
				className={styles.giftItem}
			>
				<img src={"/images/gifts/chatGift1.svg"} />
				<div className={styles.text}>Рассрочка 0% без переплат</div>
			</div>
			<div
				onClick={() =>
					handleModal("Здравствуйте! Напишите свой номер, и я подробно расскажу обо всех доступных преимуществах от OMODA и нашего салона.")
				}
				className={styles.giftItem}
			>
				<img src={"/images/gifts/chatGift2.svg"} />
				<div className={styles.text}>Реальный платеж 25 000 ₽/мес</div>
			</div>
			<div
				onClick={() =>
					handleModal("Здравствуйте! Напишите свой номер, и я подробно расскажу обо всех доступных преимуществах от OMODA и нашего салона.")
				}
				className={styles.giftItem}
			>
				<img src={"/images/gifts/chatGift3.svg"} />
				<div className={styles.text}>В наличии весь модельный ряд</div>
			</div>
		</div>
	)
}
