import getFingerPrint from "./getFingerPrint"
export default function apiRequest(model = "", phone, message, name = "Ввод не предусмотрен", brand = "omoda-transportnaya") {
	const data = {
		name: "Ввод не предусмотрен",
		phone,
		message,
		model: model,
		target: message,
		url: window.location.href,
	}
	const Comagic = window.Comagic
	const comagic_session_id = Comagic?.getSessionId() || null

	getFingerPrint()
		.then((fingerPrint) => {
			return fetch("https://maximum-auto.ru/api/sendForm/", {
				method: "POST",
				body: JSON.stringify({
					brand: brand,
					comagic_session_id,
					communication_type: "site",
					type: "leadcollector",
					date_time: new Date(),
					dep_type: "ОПНА",
					name,
					phone_number: phone,
					message,
					model: `OMODA ${model}`,
					company_id: fingerPrint,
				}),
			})
		})

		.then(() => {
			console.info("Заявка отправлена в ЛК")
		})
		.catch(console.error)

	try {
		Comagic.addOfflineRequest(data)
	} catch (error) {
		console.warn(`Отправка в Comagic не удалась. Причина ${error}`)
	}
}

