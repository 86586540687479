export const a = 1

export const globalBrandName = "Omoda"

export const transmissionsLat = {
	Вариатор: "cvt",
	Механика: "mt",
	Автомат: "at",
	Робот: "amt",
}

export const transmissionsRu = {
	cvt: "Вариатор",
	mt: "Механика",
	at: "Автомат",
	amt: "Робот",
}

export const colorsLat = {
	Белый: "white",
	Желтый: "yellow",
	Зеленый: "green",
	Красный: "red",
	Серебряный: "silver",
	Серый: "gray",
	Синий: "blue",
	Черный: "black",
}

export const colorCode = {
	Голубой: "00bfff",
	Зеленый: "#0abab5",
	Серебряный: "#9a9a9a",
	Серебристый: "#9a9a9a",
	Белый: "#ffffff",
	"Искрящийся белый": "#ffffff",
	Желтый: "#ffff00",
	Красный: "#c41e3a",
	"Огненно-красный": "#c41e3a",
	"Красный c черной крышей": "#c41e3a",
	Серый: "#708090",
	Синий: "#4169e1",
	Черный: "#000000",
	"Глубокий черный": "#000000",
}

export const driveTypeUrlDecode = {
	"perednij-privod": "Передний",
	"polnyj-privod": "Полный",
}

export const bodyTypeUrlDecode = {
	sedan: "Седан",
	crossover: "Кроссовер",
}

export const CONTACTS = {
	phone: {
		title: "+7 (812) 603-83-68",
		href: "tel:+78126038368",
	},
	address: {
		title: "Санкт-Петербург, территория Транспортная, 6",
		titleShort: "Санкт-Петербург, территория Транспортная, 6",
		href: "https://yandex.ru/maps/-/CDdwV-kH",
	},
}

export const ADDRESSES = [
	{
		address: "Санкт-Петербург, территория Транспортная, 6",
		mapHref: "https://yandex.ru/maps/-/CDdwV-kH",
	},
	{
		address: "Санкт-Петербург, Рыбинская, 1 лит. 3",
		mapHref: "https://yandex.ru/maps/-/CDTYn9q",
	}
]

export const addressOptions = [
	{id: "rybinskaya", label: "Санкт-Петербург, Рыбинская, 1 лит. 3"},
	{id: "transportnaya", label: "Санкт-Петербург, Транспортная Территория, 6"},
]
