import InputMask from "react-input-mask"
import {useState} from "react"

import styles from "./ChatForm.module.scss"
import apiRequest from "../../../../utils/request"
import ChatMessage from "../ChatMessage/ChatMessage"
import {Link} from "react-router-dom"
import ChatIcon from "../ChatIcon/ChatIcon"

export default function ChatForm({setSend, message, model}) {
	const [value, setValue] = useState("")
	const [error, setError] = useState(false)
	const [isSended, setIsSended] = useState(false)
	const [addressIndex, setAddressIndex] = useState(0)
	const brand = "omoda"

	function onChange(e) {
		if (error) setError(false)
		setValue(e.target.value)
	}

	function resetForm(e) {
		setError(false)
		setValue("")
		setIsSended(true)
		e.target.reset()
	}

	function onSubmit(e) {
		e.preventDefault()
		if (value.replace(/\D/g, "").length < 11) {
			setError(true)
			return
		}
		apiRequest(model, value, message, "Ввод не предусмотрен", brand)
		setSend(true)
		resetForm(e)
	}

	return (
		<form className={styles.form} onSubmit={onSubmit}>
			{isSended ? (
				<div className={styles.message}>Ваша заявка принята! Мы скоро свяжемся с Вами</div>
			) : (
				<>
					<ChatMessage first>Оставьте ваш номер телефона:</ChatMessage>
					<div className={styles.policy}>
						<div>
							Отправляя форму, Вы соглашаетесь
							<br /> с{" "}
							<Link className={styles.policyLink} to='/privacy-policy' target='_blank'>
								политикой конфиденциальности
							</Link>
						</div>
					</div>
					<InputMask mask='+7 (999) 999-99-99' value={value} onChange={onChange}>
						{(inputProps) => (
							<input
								{...inputProps}
								type='tel'
								required
								minLength={18}
								placeholder='+7 (___) ___-__-__'
								className={`callback__input ${error ? styles.error : ""} ${styles.input}`}
							/>
						)}
					</InputMask>
					<button className={styles.button} type='submit'>
						Узнать стоимость с выгодой
					</button>
				</>
			)}
		</form>
	)
}
